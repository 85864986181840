.homePageContainer{
  height: 100vh;
}

.myVideo {
    object-fit: cover;
    height: 100%;
    width: 100%;
    float: left;
    padding: 0;
    position: relative;
}
.videoTitle{
    position: absolute;
    bottom: 80px;
    left: 40px;
}
.prevArrow{
    width: 20px;
    position: absolute;
    top:50%;
    left: 2%;
    z-index: 5;
}
.nextArrow{
    width: 20px;
    position: absolute;
    top:50%;
    right: 2%;
    transform: rotate(180deg);
}

@media (max-width: 980px) {
    .videoTitle{
        bottom:5%;
        left:50%;
        transform:translate(-50%, -50%);
    }
}


