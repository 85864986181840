.projectViewContainer{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    margin-right: 10px;
}
.modElement{
    border:2px solid black;
    display: flex;
    height: 100%;
    padding-left: 20px;
}
.modElementTitle{
    font-weight: bold;
    padding-right: 10px;
}
