.topWrapper {
    height: 150vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.topWrapper:after {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 80%;
    content: '';
}

.contactsTitle {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: rgba(68, 70, 64, 0.3);
    font-family: 'Apexnewweb book', sans-serif;
    color: #f5f1f1;
    font-size: 62px;
    line-height: 35px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 20px;
    text-transform: uppercase;
    width: 70%;
    z-index: 4;
}
.inputsContainer{
    /*border:2px solid red;*/
    max-width: 80%;
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap:30px;
    margin-top: 30px;
}
.contactsContainer{
    max-width: 80%;
    width: 100%;
    z-index: 2;
    display: flex;
    padding: 10px;
    gap:30px;
    margin-top: 30px;
    justify-content: space-around;

}
.topInputsContainer{
    display: flex;
    justify-content: space-between;
    /*border:2px solid green;*/
}
.stringInput{
    max-width: 45%;
    width: 100%;
    font-family: inherit;
    border: 0;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 1.3rem;
    color: #fff;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
}
.stringInput:focus{
    background-color: black;
}
.textareaStyle{
    max-width: 100%;
    width: 100%;
    resize: none;
    height: 200px;

    font-family: inherit;
    border: 0;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 1.3rem;
    color: #fff;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
}


.form__group {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    width: 45%;
}
.form__field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 1.3rem;
    color: #fff;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
}
.form__field::placeholder {
    color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
}
.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #9b9b9b;
}
.form__field:focus {
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-image: linear-gradient(to right, #393a3d, #b8c2ce);
    border-image-slice: 1;
}
.form__field:focus ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #efeded;
    font-weight: 700;
}
/* reset input */
.form__field:required, .form__field:invalid {
    box-shadow: none;
}
@media (max-width: 850px) {
    .topWrapper{
        height: 170vh;
    }
    .inputsContainer{
        margin-top: 150px;
    }
    .topInputsContainer{
        flex-direction: column;
        gap:20px;
    }
    .form__group{
        width: 100%;
    }
    .contactsContainer{
        flex-direction: column;
    }
}


.addressBlock {
    display: flex;
    flex-direction: column;
    justify-content: left;

}
.addressText{
    margin-top: 0;
    margin-bottom: 10px;
    font-family: Nunito,sans-serif;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0;
    color: #fff;
    text-transform: uppercase;
}
.addressLabel{
    display: inline-block;
    margin-right: 10px;
    padding: 5px 10px;
    background-color: #8d9382;
    font-family: Apexnewweb,sans-serif;
    color: #fff;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: bold;
}
