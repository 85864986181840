.pageContainer{
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.authPageTitle{
    text-align: center;
}
.form__group {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    max-width: 400px;
    width: 100%;
    margin-bottom: 40px;
}
.form__field {
    font-family: inherit;
    width: 100%;
    border: 2px solid #9b9b9b;
    outline: 0;
    font-size: 1.3rem;
    color: #36363a;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
}
.form__field::placeholder {
    color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
}
.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #9b9b9b;
    margin-left: 10px;
}
.form__field:focus {
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-image: linear-gradient(to right, #393a3d, #b8c2ce);
    border-image-slice: 1;
}
.form__field:focus ~ .form__label {
    position: absolute;
    top: -10%;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #a2a0a0;
    font-weight: 700;
}
/* reset input */
.form__field:required, .form__field:invalid {
    box-shadow: none;
}
