.personModalContainer{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0; top: 0; right: 0; bottom: 0;
    z-index: 6;

}
.modalOverlay{
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    background-color: #2c2d29;
    opacity: 85%;
    z-index: 7;
}
.modalContainer{
    position: absolute;
    top:30%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
    width: 100%;
    height: 450px;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    display: flex;
    flex-direction: column;
    z-index: 15;
    filter: grayscale(1);


    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 150px;
    background-position-y: -70px;
    background-color: #1f0b0b;
}
.modalContainerInnerOverlay{
    width: 100%;
    height: 100%;
    padding: 20px;
    background-image: linear-gradient(90deg,#fff,hsla(0,0%,100%,.7));
    overflow-y: scroll;

}
 .body {
    flex: 50%;
    display: flex;
     flex-direction: column;
    justify-content: center;
    align-items: center;
    animation-delay: 500ms;

}

.title{
    font-family: Nunito, sans-serif;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 5px;
    text-transform: uppercase;
    color: #8d9382;
    font-weight: 100;
    text-align: left;
    background-color: transparent;
}
.modalText{
    font-family: Nunito,sans-serif;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
    color: #8d9382;
}
.modalContainerInner{
    display: flex;
    flex-direction: column;


}
.closeButton{
    position: absolute;
    top:30px;
    right: 30px;
    cursor: pointer;
    z-index: 10;

}
@media (max-width: 750px) {
    .modalContainer{
        top:0;
        height: 100%;
        z-index: 150 !important;

        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: 1px;
        background-position-y: 1px;
    }
    .modalContainerInner{
        margin-top: 100px;

    }
    .closeButton{
        top: 100px;
    }
}
