.projectCardContainer{
    width: 32%;
    min-width: 350px;
    height: 500px;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    margin: 0 5px 20px 5px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.projectCardContainer::before {
    content: "";
    position: absolute;
    top:60%;
    left: 15%;
    display: block;
    width: 70%;
    height: 1px;
    background-color: #fdfdfd;
    transform: scaleX(0);
    transition: transform 0.5s ease;
    z-index: 1000;
}

.projectCardContainer:hover::before {
    transform: scaleX(1);

}
.projectContainerHover{
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(353deg,#000,rgba(76,185,214,.5));
    opacity: 0;
    transition: opacity 0.3s;
}
.projectCardContainer:hover > .titleContainer p {
    opacity: 100;
}

.titleContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:white;
    padding-right: 15px;
    padding-left: 15px;
    z-index: 1000;
    text-align: center;
    width: 100%;
}
.projectTitle{
    text-align: center;
    font-family: Apexnewweb,sans-serif;
    font-size: 40px;
    line-height: 36px;
    font-weight: 300;
    letter-spacing: 5px;
    text-transform: uppercase;
    opacity: 0;
    transition: opacity 0.4s;
    transition-delay: 0.5s;

}

.projectText{
    text-align: center;
    text-transform: uppercase;
    font-family: Apexnewweb,sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    letter-spacing: 4px;
    opacity: 0;
    transition: opacity 0.4s;
    transition-delay: 0.7s;
    margin-top: 30px;
}

@media (max-width: 950px) {
    .projectCardContainer::before{
        transform: scaleX(1);
    }
    .titleContainer p{
        opacity: 100;
    }
    .projectContainerHover{
        opacity: 100 !important;
    }
}
@media (max-width: 730px) {
   .projectCardContainer{
       width: 100%;
       height: 400px;
   }
}
