.personalCardContainer {
    width: 32%;
    min-width: 350px;
    height: 500px;
    object-fit: cover;
    background-size: cover;
    margin: 0 5px 20px 5px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.personalCardContainerCreators {
    width: 49%;
    min-width: 350px;
    height: 500px;
    object-fit: cover;
    background-size: cover;
    margin: 0 5px 20px 5px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

/*.personalCardContainer:after {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    content: "";*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    background: linear-gradient(40deg,#8d9382 9%,rgba(141,147,130,.86));*/
/*    opacity: 1%;*/
/*    transition: opacity 0.3s;*/

/*}*/
/*.personalCardContainer:hover::after{*/
/*    opacity:80%;*/
/*}*/
.personalCardContainerOverlay{
    position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 100%;
        background: linear-gradient(40deg,#8d9382 9%,rgba(141,147,130,.86));
        transition: opacity 0.3s;
}
.personalCardTitle {
    max-width: 80%;
    width: 100%;
    position: relative;
    margin:0 0 10px 10px;
    z-index: 10;
}
@media (max-width: 740px) {
    .personalCardContainer{
        width: 100%;
        height: 300px;
        background-position: right;
        background-position-y: 15%;
        object-fit: fill;
        justify-content: center;
        align-items: flex-end;
    }
}
