body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
#root > div {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.SimpleSlider_myVideo__DTBG3{
  height: 100vh !important;
}
.Projects_projectsBlockVideoBg__WvNOt {
  object-fit: cover  !important;
}
.slick-dots{
  position: absolute;
  top:90%;
  left:0;
  height: 0;
  padding: 0;
  margin: 0;
  background-color: #a61730;
}
.slick-slider,
.slick-track,
.slick-slide,
.slick-slide > div,
.slick-slide > div > div,
.slick-list {
  height: 100vh !important;
}
@media (max-width: 1005px) {
  .pageTitle {
    padding-top: 20px;
    padding-bottom: 20px;
    line-height: 65px;
  }
}


@media (max-width: 950px) {
  .projectTitle {
    border: 2px solid grey;
    background-color: transparent;
    padding-top: 20px;
    padding-bottom: 20px;
    line-height: 65px;
  }
}

@media (max-width: 750px) {
  .buttonsContainer {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

@media (max-width: 450px) {
  .projectTitle {
    font-size: 30px;
    line-height: 42px;
    letter-spacing: 5px;
    width: 95%;
    background-color: transparent;
  }
}
