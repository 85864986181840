.footerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

.footerLogo {
    width: 150px;
    opacity: 70%;
}

.footerDevider {
    width: 90%;
    height: 1px;
    background-color: #a69d9d;
    margin-top: 15px;
    margin-bottom: 15px;
}

.footerNavButtonsBlock {
    display: flex;
}

.footerSocialMediaLinks {
    display: flex;
    justify-content: space-around;
    max-width: 300px;
    width: 100%;

}

.socialLinkImg {
    opacity: 50%;
    transition: all 0.5s;
    width: 30px;

}

.socialLinkImg:hover {
    opacity: 100%;
}

@media (max-width: 750px) {
    .footerNavButtonsBlock {
        flex-direction: column;
    }
}
