.teamTop {
    height: 100vh;
    background-image: url("https://apextest12.b-cdn.net/officeBcg.webp");
    filter: grayscale(1);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}

.teamTopOverlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100vh;
    background-color: #FFFFFFCE;
    opacity: 80%;
    z-index: 10;
}

.teamTitle {
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: rgba(133, 190, 27, 0.3);
    font-family: 'Apexnewweb book', sans-serif;
    color: #4b4b4b;
    font-size: 72px;
    line-height: 35px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 20px;
    text-transform: uppercase;
}

.teamTitleBottom {
    font-family: Nunito, sans-serif;
    color: #4b4b4b;
    font-size: 21px;
    line-height: 30px;
    text-align: center;
    font-weight: 700;
    max-width: 700px;
}

.generalPhoto {
    max-width: 1550px;
    width: 100%;
    margin-bottom: 20px;
}
.teamBlock{
    display: flex;
    flex-direction: column;
}
.teamBlockTitle{
    margin-top: 20px;
    margin-bottom: 20px;
    /*background-color: rgba(192, 190, 190, 0.7);*/
    font-family: 'Apexnewweb book', sans-serif;
    color: #4b4b4b;
    font-size: 32px;
    line-height: 35px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 5px;
    text-transform: uppercase;
}
.teamPhotos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1550px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.teamPhotosMod {
    display: grid;
    column-gap: 50px;
    grid-template-columns: 300px  300px;
}

.generalPhotoContainer {
    display: flex;
    max-width: 1550px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.joelFinder {
    width: 10%;
    height: 30%;
    position: absolute;
    top: 35%;
    left: 69%;
}
.lauraFinder{
    width: 10%;
    height: 40%;
    position: absolute;
    top: 45%;
    left: 80%;
}
.paolaFinder{
    width: 10%;
    height: 37%;
    position: absolute;
    top: 24%;
    left: 55%;
}
.valentinoFinder{
    width: 10%;
    height: 27%;
    position: absolute;
    top: 36%;
    left: 40%;
}

.joinTeamWrapper {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid white;
    font-weight: bold;
    max-width: 350px;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 10px 30px;

}

.joinTeamWrapper:after {
    background: black;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    opacity: 5%;
    z-index: 5;
    transition: opacity 0.5s;

}
.joinTeamWrapper:hover::after{
    opacity: 50%;
}
.personTitleContainer{
    transition: opacity 0.5s;
    opacity: 0;
    z-index: 20;
    width: 150px;
    position: absolute;
    left:0;
    top:60%;
}

.personTitle{
    font-family: 'Apexnewweb book', sans-serif;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    z-index: 50;
}

.personPosition{
    font-family: 'Apexnewweb book', sans-serif;
    text-transform: uppercase;
    color: white;
    font-size: 14px;
    z-index: 50;
}
.joinTeamBtn {
    color: white;
    font-family: 'Apexnewweb book', sans-serif;
    text-transform: uppercase;
    align-self: center;
    z-index: 7;
}

@media (max-width: 950px) {
    .teamTitle {
        font-size: 62px;

    }
}

@media (max-width: 750px) {
    .teamTitle {
        line-height: 55px;

    }
    .joelFinder,
    .paolaFinder {
        display: none;
    }
    .teamBlockTitle{
        color: #4b4b4b;
        font-size: 22px;
        line-height: 25px;
        font-weight: 400;
        text-align: center;
        letter-spacing: 5px;
        text-transform: uppercase;
    }
}

@media (max-width: 450px) {
    .teamTop{
        height: 60vh;
    }
    .teamTopOverlay{
        height: 60vh;
    }
    .teamTitle {
        font-size: 30px;
        line-height: 42px;
        letter-spacing: 5px;
        padding: 10px 15px;
        background: transparent;
        border: 2px solid #000000;
        margin-top: 0;
    }

    .teamTitleBottom {
        font-size: 18px;
        max-width: 90%;
    }
    .teamBlockTitle{
        color: #4b4b4b;
        font-size: 20px;
        line-height: 22px;
        font-weight: 400;
        text-align: center;
        letter-spacing: 3px;
        text-transform: uppercase;
    }
}
