.slide-pane__header{
    background-color: #8d9382;
}
.slide-pane__overlay{
    z-index: 100;
}
.slide-pane__close{
    position: absolute;
    right: -30px;
    top:58%;
    opacity: 100;
}
.slide-pane__close:after{
    position: absolute;
    top:0;
    right: -70px;
    content: 'DETAILS';
    display: flex;
    justify-content: center;
    align-items: center;
    rotate: 90deg;
    width: 150px;
    height: 50px;
    background: #8d9382;
    font-family: Apexnewweb,sans-serif;
    color: #f0f1ef;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 4px;
}
.slide-pane__content{
    background:#8d9382;

}


