.header{
    padding-right: 20px;
    padding-left: 20px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: auto;
    z-index: 100;
    display: flex;
    width: 100%;
    background-image: linear-gradient(180deg,rgba(0,0,0,.62),rgba(75,75,75,0));
    height: 100px;
    justify-content: space-between;
    align-items: center;

}

.headerImg{
    max-width: 150px;
    opacity: 50%;
}

.menuNav{
    display: flex;
    position: relative;
}
.menuBars{
    display: none;
}
@media (max-width: 995px) {
    .menuNav{
        display: none;
    }
    .menuBars{
        display: flex;
        margin-bottom: 10px;
        opacity: 50%;
    }
    .headerImg{
        max-width: 110px;
    }
}

