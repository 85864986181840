.PopUpTitleWrapper{
    position: relative;

}

.title {
    font-family: Apexnewweb, sans-serif;
    font-size: 24px;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: 5px;
    text-transform: uppercase;
    color: white;
    position: relative;
    opacity: 0;
    transition: opacity 0.4s;
    transition-delay: 0.4s;
    margin-bottom: 10px;
}

.text {
    font-family: Apexnewweb, sans-serif;
    font-size: 18px;
    line-height: 18px;
    font-weight: 300;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: white;
    position: relative;
    opacity: 0;
    transition: opacity 0.4s;
    transition-delay: 0.6s;

}
.divider {
    display: block;
    width: 100%;
    height:2px;
    background-color: #f3f1f1;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.4s ease;
}

.titleBtn {
    background-color: transparent;
    display: flex;
    padding: 5px 10px;
    border: 1px solid #FFFFFF7F;
    color: #fff;
    font-size: 12px;
    letter-spacing: 4px;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    opacity: 0;

}
@media (max-width: 980px) {
    .PopUpTitleWrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
@media (max-width: 480px) {
    .title{
        text-align: center;
    }
}
